import React from "react"
import serviceone from "../../../static/images/serviceone.png"
import Data from "../../../static/images/Datamining.jpg"

export default function sectionone() {
  return (
    <div className=""style={{fontFamily:'poppins'}}>
      {/* <section
        class=" px-12 lg:py-16 lg:px-20 bg-white overflow-hidden"
        style={{ fontFamily: "poppins" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap xl:items-center -m-8">
            <div class="w-full md:w-1/2 p-8">
              <img
                class="pt-12 lg:pt-4 lg:mx-auto transform hover:translate-y-3 transition ease-in-out lg:w-3/4 lg:h-3/4 duration-1000"
                src={Machine}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-xl">
                <p class="mb-2 text-sm text-indigo-900 font-semibold uppercase tracking-px">
                  &#x1F44B; Our Services
                </p>
                <h2 class=" mb-16 text-2xl md:text-4xl font-bold text-indigo-800 font-heading tracking-px-n leading-tight">
                  Machine Learning and Artificial Intelligence
                </h2>
                <div class="flex flex-wrap mb-5 pb-10 -m-8">
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Valuation
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            {" "}
                            liabilities in an insurance company by preparation
                            of regulatory & financial condition reports (FCR)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Preparation
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Preparation of claims reserve estimation and
                            adequacy assessment
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Reinsurance
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Reinsurance appropriateness and sufficiency
                            assessment
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-8">
                    <div class="md:max-w-xs">
                      <div class="flex flex-wrap -m-2">
                        <div class="w-auto p-2">
                          <svg
                            class="mt-1"
                            width="24"
                            height="24"
                            viewbox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z"
                              stroke="#4F46E5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex-1 p-2">
                          <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">
                            Analysis
                          </h3>
                          <p class="text-gray-600 font-medium leading-relaxed">
                            Analysis of claims experience of an insurance
                            company
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="md:inline-block">
            <button class="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Get Started Now</button>
          </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section class=" py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-7xl mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            <div class="bg-white rounded-lg overflow-hidden p-6 shadow-lg">
              <h2 class="text-base font-semibold leading-7 text-indigo-600">
                Our Services
              </h2>
              <h3 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Data Mining and Engineering
              </h3>
              <p class="text-lg leading-8 text-gray-600 mt-5">
                RJ Actuaries & Consultants utilizes advanced data mining
                techniques to extract valuable insights from complex datasets,
                enabling clients to make well-informed, data-driven decisions.
              </p>
              <ul class="list-disc ml-6 mb-4 mt-5">
                <li>Data Mining Modules</li>
                <li>Data Integrity and Forensics Modules</li>
                <li>
                  Data processing, data cleaning, and preparing ready-to-use
                  data for further analysis by data analysts and scientists.
                </li>
                <li>
                  Transforming data ready for analysis that can deliver progress
                  and transformation for your business.
                </li>
              </ul>
            </div>

            <div class="bg-white rounded-lg overflow-hidden shadow-lg">
              <img
                class="h-96 w-full transform hover:scale-105 transition ease-in-out duration-1000"
                src={Data}
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}

      <div
        class="flex flex-col lg:flex-row bg-gray-100 py-12 px-4 sm:px-6 lg:px-20 pt-20"
        style={{ fontFamily: "poppins" }}
      >
        {/* <!-- Text Section --> */}
        <div class="lg:w-1/2 lg:pr-12">
          <h2 class="text-base font-semibold leading-7 text-indigo-600">
            Our Services
          </h2>
          <h2 class="text-2xl lg:text-3xl font-semibold mb-4 text-blue-900">
            Data Mining and Engineering
          </h2>
          <p class="text-gray-700 mb-6">
            RJ Actuaries & Consultants utilizes advanced data mining techniques
            to extract valuable insights from complex datasets, enabling clients
            to make well-informed, data-driven decisions.
          </p>

          <h3 class="text-sm lg:text-xl mb-4">Specializations</h3>
          <ul class="list-disc ml-6 mb-6">
            <li>Data Mining Modules</li>
            <li>Data Integrity and Forensics Modules</li>
            <li>Predictive Modelling</li>
            <li>Stochastic Modelling</li>
            <li>Data interpretation</li>
          </ul>

          <h3 class="text-sm lg:text-xl mb-4">Assisting Clients With</h3>
          <ul class="list-disc ml-6 mb-6">
            <li>Data processing</li>
            <li>Data cleaning</li>
            <li>Preparing ready-to-use data</li>
          </ul>

          <p class="text-gray-700 mb-6">
            We help businesses leverage data analytics tools and techniques to
            gain actionable insights, improve operational efficiency, and drive
            business growth. We provide data-driven recommendations to optimize
            processes and enhance performance.
          </p>
        </div>

        {/* <!-- Image Section --> */}
        <div class="lg:w-1/2">
          <img
            src={Data}
            alt="Image"
            class="w-full h-auto lg:mx-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  )
}
